<template>
  <main>
    <div class="card shadow-none mt-0 border-0">
      <div class="card-body">
        <div class="row">
          <div class="list-group mb-3 col-lg-4 col-md-12 col-sm-12" v-for="item in riesgos" :key="item.id">
            <div class="list-group-item mb-0">
              <div class="form-row d-flex mb-0">
                <label class="small text-black mx-2">{{ item.nombre }}</label>
                <radioButtonSingle :opciones="opciones" v-model="item.respuesta"></radioButtonSingle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  <table class="table table-striped table-sm table-bordered font-size-12">
      <tr>
        <th>Factores</th>
        <th>Respuesta</th>
      </tr>
      <tr v-for="item in riesgos" :key="item.id">
        <td>{{ item.nombre }}</td>
        <td>
          <radioButtonSingle :opciones="opciones" v-model="item.respuesta"></radioButtonSingle>
          <select v-model="item.respuesta" class="form-control">
            <option value="SI">SI</option>
            <option value="NO">NO</option>
          </select>
        </td>
      </tr>
    </table> -->
    <div class="alert alert-danger" v-if="$v.riesgos.$error && $v.riesgos.$dirty">Debe completar el formulario</div>

  </main>
</template>
<script>
import Toast from '../../../../components/common/utilities/toast';
import riesgoCardiovascularService from "../../../../services/riesgoCardiovascularService";
import historiaRiesgosCardiovascularService from "../../../../services/historiaRiesgosCardiovascularService";
import { isEmpty } from "lodash";
import { required } from "vuelidate/lib/validators";
import radioButtonSingle from '../../../../components/common/radioButtons/radioButtonSingle';
export default {
  props: ["idHistoria", "finalizada", "idUsuario"],
  components: { radioButtonSingle },
  data() {
    return {
      respuesta: {},
      riesgos: {},
      tipo: 'modificable',
      tieneRespuestas: false,
      opciones: [
        {
          id: 1,
          value: 'SI',
          name: 'SI'
        },
        {
          id: 2,
          value: 'NO',
          name: 'NO'
        }
      ]
    };
  },
  validations() {
    return {
      riesgos: {
        $each: {
          respuesta: { required }
        }
      }
    }
  },
  methods: {
    async cargarRiesgos() {

      const response = await riesgoCardiovascularService.showByTipo(this.tipo);
      const respuestas = await historiaRiesgosCardiovascularService.showByHc(this.idHistoria);

      if (!isEmpty(respuestas.data)) this.tieneRespuestas = true;

      this.riesgos = response.data.map((riesgo) => {

        let respuesta = '';

        if (!isEmpty(respuestas.data)) {

          const tieneRespuesta = respuestas.data.find(x => parseInt(x.id_riesgo) === parseInt(riesgo.id));

          if (tieneRespuesta !== undefined) {
            respuesta = tieneRespuesta.respuesta;
          }

        }

        return {
          ...riesgo,
          respuesta: respuesta,
        };
      });
    },
    retornar() {

      this.$v.$touch();

      if (this.$v.$invalid) return;

      let riesgo_modificable = {
        id_historia: this.idHistoria,
        respuestas: this.riesgos,
      };

      return riesgo_modificable;
    },
    async guardar() {
      try {
        let historia_riesgos = {
          id_historia: this.idHistoria,
          respuestas: this.riesgos,
        };

        this.LoaderSpinnerShow();

        if (this.tieneRespuestas) {
          await historiaRiesgosCardiovascularService.update(historia_riesgos);
        } else {
          await historiaRiesgosCardiovascularService.store(historia_riesgos);
          this.tieneRespuestas = true;
        }

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: 'success',
          title: 'Datos guardados con exito'
        });
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'error',
          title: 'Ocurrio un error al procesar la solicitud'
        });
      }
    },
  },
  created() {
    this.cargarRiesgos();
  },
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>