<template>
    <div class="container-r">
        <label @click="selectOption(i)" v-for="(opc, i) in opciones" :data-opc="`opc_${opc.id}`" :key="`${i}_opc`">
            <input type="radio" v-model="opcion" :value="opc.value">
            <span>{{ opc.name }}</span>
        </label>
    </div>
</template>
<script>

export default {
    props: ['label', 'value', 'opciones'],
    data() {
        return {
            opcionSelccionada: '',
            opcion: '',
        }
    },
    methods: {
        selectOption(i) {
            const trim = this.opciones[i];
            this.opcionSelccionada = trim;
        },
    },
    watch: {
        value: async function (value) {

            if (value === "" || value === undefined || value === null) {
                this.opcion = "";
                return;
            }

            this.opcion = value;
        },
        opcion: function (value) {
            this.$emit('input', value);
        },
    },
    created() {
        this.opcion = this.value;
    },
}
</script>
<style scoped>
.container-r label {
    /* Remove the display: flex property, as we want the labels to display inline */
    cursor: pointer;
    font-weight: 500;
    font-size: 11px;
    position: relative;
    margin-right: 0px;
    /* Adjust margin to create spacing between labels */
}

.container-r label:first-child {
    margin-left: 0px;
    /* Remove left margin from the first label */
}

.container-r label input {
    position: absolute;
    left: -9999px;
}

.container-r label input:checked+span {
    background-color: #6900c7;
    color: white;
}

.container-r label input:checked+span:before {
    box-shadow: inset 0 0 0 0.4375em #4a018a;
}

.container-r label span {
    display: inline-flex;
    /* Change to inline-flex to display horizontally */
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
    color: #000;
}

.container-r label span:hover {
    background-color: #d6d6e5;
}

.container-r label span:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #c5c5c5;
}</style>